import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { isBrowser, getAnalyticIDs } from "@cinch-labs/shared-util";
export var sendDataLayerEvent = function(data) {
    if (isBrowser) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var addServiceTrackingIdsToEvent = function(data) {
    if ("object" === "undefined" || !Object.prototype.hasOwnProperty.call(data, "event")) {
        return data;
    }
    var ref = getAnalyticIDs(), adobeID = ref.adobeID, gaID = ref.gaID;
    var event = data["event"];
    if (adobeID) {
        event.adobeID = adobeID;
    }
    if (gaID) {
        event.gaID = gaID;
    }
    return _object_spread_props(_object_spread({}, data), {
        event: event
    });
};
export var sendDigitalDataEvent = function(data) {
    if (window && data) {
        window.digitalData = window.digitalData || [];
        var trackableEvent = addServiceTrackingIdsToEvent(data);
        window.digitalData.push(trackableEvent);
    }
};
export var addToDigitalDataEvent = function(data) {
    if ("object" !== "undefined" && window.digitalData.length > 0) {
        var updatedDigitDataLayer = window.digitalData.reduce(function(dataLayer, event) {
            var updatedDataLayerObject = _object_spread({}, event, data);
            return [
                _object_spread({}, dataLayer, updatedDataLayerObject)
            ];
        }, []);
        window.digitalData = updatedDigitDataLayer;
    }
};
export var sendHotjarEvent = function(event) {
    if ("object" !== "undefined" && window.hj) {
        window.hj("event", event);
    }
};
export var sendHotjarTag = function(tag) {
    if ("object" !== "undefined" && window.hj) {
        window.hj("tagRecording", [
            tag
        ]);
    }
};
export var sendHotjarTrigger = function(triggerName) {
    if ("object" !== "undefined" && window.hj) {
        window.hj("trigger", triggerName);
    }
};

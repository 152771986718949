import _object_spread from "@swc/helpers/src/_object_spread.mjs";
export var BaseEnvironment = {
    RedirectUrlKey: "REDIRECT_URL_KEY"
};
export var EnvironmentFromFile = {
    Adobe: "ADOBE_DTM",
    LegacyAuthKey: "IDS_AUTH_KEY",
    AuthKey: "AUTH0_AUTH_KEY",
    ProfileServiceUrl: "PROFILE_SERVICE_URL",
    StaticAssetsUrl: "STATIC_ASSETS_URL",
    OrderServiceUrl: "ORDER_SERVICE_URL",
    CinchcareServiceUrl: "CINCHCARE_SERVICE_URL",
    DeliveryServiceURl: "DELIVERY_SERVICE_URL",
    SearchServiceUrl: "SEARCH_SERVICE_URL",
    SearchValuePropositions: "SEARCH_VALUE_PROPOSITIONS",
    SegmentKey: "SEGMENT_KEY",
    IdentityServiceUrl: "IDENTITY_SERVICE_URL",
    Auth0ServiceUrl: "AUTH0_SERVICE_URL",
    Auth0DatabaseName: "AUTH0_DATABASE_NAME",
    Auth0ClientId: "AUTH0_CLIENT_ID",
    promoBannerName: "PROMO_BANNER_NAME",
    cinchUrl: "CINCH_URL",
    cinchAppUrl: "CINCH_APP_URL",
    TrustpilotUrl: "TRUSTPILOT_URL",
    StoryblokBFFComponent: "STORYBLOK_BFF_COMPONENT",
    consumerValuationsServiceUrl: "CONSUMER_VALUATIONS_SERVICE_URL",
    CinchChargeCardRequestUrl: "CINCH_CHARGE_CARD_REQUEST_URL",
    CinchChargeCardRequestAPIKey: "CINCH_CHARGE_CARD_REQUEST_API_KEY",
    ProductDetailServiceUrl: "PRODUCT_DETAIL_SERVICE_URL",
    ReservationStatusUrl: "RESERVATION_STATUS_URL",
    InventoryServiceHistoryUrl: "INVENTORY_SERVICE_HISTORY_URL",
    IOSAppStoreUrl: "IOS_APP_STORE_URL",
    AndroidAppStoreUrl: "ANDROID_APP_STORE_URL",
    AdminFeeReturnToCheckout: "ADMIN_FEE_RETURN_TO_CHECKOUT",
    AdminFeeValue: "ADMIN_FEE_VALUE",
    AdminFeeLoyalty: "ADMIN_FEE_LOYALTY",
    CheckoutReservationsDecoupling: "CHECKOUT_RESERVATIONS_DECOUPLING",
    CheckoutReservationsDecouplingPhaseTwo: "CHECKOUT_RESERVATIONS_DECOUPLING_PHASE_TWO",
    ReservationServiceUrl: "RESERVATION_SERVICE_URL",
    DeliverySlotAvailabilityServiceUrl: "DELIVERY_SLOT_AVAILABILITY_SERVICE_URL",
    FinanceApplicationServiceUrl: "FINANCE_APPLICATION_SERVICE_URL",
    FinanceQuoteServiceUrl: "FINANCE_QUOTE_SERVICE_URL",
    FinanceSoftSearchUrlPath: "FINANCE_SOFT_SEARCH_URL_PATH",
    PaymentLinkServiceUrl: "PAYMENT_LINK_SERVICE_URL",
    StripePublishableKey: "STRIPE_PUBLISHABLE_KEY",
    GapServiceUrl: "GAP_SERVICE_URL",
    EnableAmPmSlotsForDeliveries: "ENABLE_AM_PM_SLOTS_FOR_DELIVERIES",
    ServicingAndWarrantyFlag: "SERVICING_AND_WARRANTY_FLAG",
    DisableCinchCare: "DISABLE_CINCHCARE",
    enableFomoMessaging: "ENABLE_FOMO_MESSAGING",
    GapInsuranceFlag: "GAP_INSURANCE_FLAG",
    CustomerDataAPIKey: "CUSTOMER_DATA_API_KEY",
    CustomerDataAPIUrl: "CUSTOMER_DATA_API_URL",
    EnableRequestCallback: "ENABLE_REQUEST_CALLBACK",
    EnableCinchAppUserAgentInUsedCarsSSR: "ENABLE_CINCH_APP_USER_AGENT_IN_USED_CARS_SSR",
    ProductMetaServiceUrl: "PRODUCT_META_SERVICE_URL",
    SearchMultipleImages: "SEARCH_MULTIPLE_IMAGES",
    EnabledZendeskScript: "ENABLE_ZENDESK_SCRIPT",
    SearchAvailableToView: "SEARCH_AVAILABLE_TO_VIEW",
    VehicleCardV2: "VEHICLE_CARD_V2",
    NextBestAction: "NEXT_BEST_ACTION",
    SearchStoreLocations: "SEARCH_STORE_LOCATIONS",
    VehicleOwnershipServiceUrl: "VEHICLE_OWNERSHIP_SERVICE_URL",
    EnableChangeVehicle: "ENABLE_CHANGE_VEHICLE",
    AdminFeeCompliance: "ENABLE_ADMIN_FEE_COMPLIANCE",
    SearchUsedCarsValidationV2: "SEARCH_USED_CARS_VALIDATION_V2",
    enableBuyNowMessaging: "ENABLE_BUY_NOW_CTA_MESSAGING",
    SearchVehicleOpenNewTab: "SEARCH_VEHICLE_OPEN_NEW_TAB",
    EnableFavouritingModalChanges: "ENABLE_FAVOURITING_MODAL_CHANGES",
    EnableFavouritingHomePageChanges: "HOMEPAGE_FAVOURITING",
    ShouldShowCinchCare: "SHOULD_SHOW_CINCH_CARE"
};
export var EnvironmentFromProcess = {
    DataDogAPIKey: "DATADOG_API_KEY",
    DataDogClientId: "DATADOG_CLIENT_ID",
    DataDogRumApplicationId: "DATADOG_RUM_APPLICATION_ID",
    DataDogRumClientToken: "DATADOG_RUM_CLIENT_TOKEN",
    DataDogRumGlobalSampleRate: "DATADOG_RUM_GLOBAL_SAMPLE_RATE",
    DataDogRumGlobalReplaySampleRate: "DATADOG_RUM_GLOBAL_REPLAY_SAMPLE_RATE",
    DataDogServiceName: "DATADOG_SERVICE_NAME",
    BuildId: "BUILD_ID"
};
export var Env = _object_spread({}, BaseEnvironment, EnvironmentFromFile, EnvironmentFromProcess);

import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { JsonLDType } from "../json-ld-script";
export var formatBreadcrumbListSchema = function(breadcrumbs) {
    return {
        "@type": JsonLDType.BreadcrumbList,
        itemListElement: breadcrumbs.map(function(crumb, index) {
            return {
                "@type": JsonLDType.ListItem,
                position: index + 1,
                name: crumb.text,
                item: "".concat(process.env.STAGE_URL).concat(crumb.url)
            };
        })
    };
};
export var formatFaqPageSchema = function(faqs) {
    return {
        "@type": JsonLDType.FaqPage,
        mainEntity: faqs.map(function(param) {
            var question = param.question, summary = param.summary;
            return {
                "@type": JsonLDType.Question,
                name: question,
                acceptedAnswer: {
                    "@type": JsonLDType.Answer,
                    text: summary
                }
            };
        })
    };
};
export var formatVideoSchema = function(video) {
    return {
        "@type": JsonLDType.VideoObject,
        name: video.title,
        description: video.description,
        thumbnailUrl: video.thumbnail ? [
            video.thumbnail.filename
        ] : undefined,
        duration: video.duration,
        uploadDate: video.uploadDate
    };
};
export var formatHowToSchema = function(howto) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    return {
        "@type": JsonLDType.HowTo,
        name: (ref = howto[0]) === null || ref === void 0 ? void 0 : ref.name,
        image: (ref1 = howto[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.image) === null || ref2 === void 0 ? void 0 : ref2.map(function(p) {
            return {
                "@type": "ImageObject",
                url: p.url,
                height: p.height,
                width: p.width
            };
        }),
        video: (ref3 = howto[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.video) === null || ref4 === void 0 ? void 0 : ref4.map(function(p) {
            var ref;
            return {
                "@type": "VideoObject",
                name: p.name,
                description: p.description,
                thumbnailUrl: p.thumbnailUrl,
                contentUrl: p.contentUrl,
                embedUrl: p.embedUrl,
                uploadDate: p.uploadDate,
                duration: p.duration,
                hasPart: (ref = p.hasPart) === null || ref === void 0 ? void 0 : ref.map(function(element) {
                    return {
                        "@type": "Clip",
                        "@id": element.id,
                        name: element.name,
                        startOffset: element.startOffset,
                        endOffset: element.endOffset,
                        url: element.url
                    };
                })
            };
        }),
        step: (ref5 = howto[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.step) === null || ref6 === void 0 ? void 0 : ref6.map(function(p) {
            var ref, ref1, ref2, ref3;
            return {
                url: p.url,
                name: p.name,
                "@type": "HowToStep",
                image: {
                    "@type": "ImageObject",
                    url: (ref = p === null || p === void 0 ? void 0 : p.image[0]) === null || ref === void 0 ? void 0 : ref.url,
                    height: (ref1 = p.image[0]) === null || ref1 === void 0 ? void 0 : ref1.height,
                    width: (ref2 = p.image[0]) === null || ref2 === void 0 ? void 0 : ref2.width
                },
                itemListElement: (ref3 = p.itemListElement) === null || ref3 === void 0 ? void 0 : ref3.map(function(element) {
                    return {
                        "@type": "HowToDirection",
                        text: element.text
                    };
                })
            };
        }),
        supply: (ref7 = howto[0]) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.supply) === null || ref8 === void 0 ? void 0 : ref8.map(function(element) {
            return {
                "@type": "HowToSupply",
                name: element.name
            };
        }),
        tool: (ref9 = howto[0]) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.tool) === null || ref10 === void 0 ? void 0 : ref10.map(function(element) {
            return {
                "@type": "HowToTool",
                name: element.name
            };
        })
    };
};
export var formatReviewToSchema = function(review, publishedDate, prosAndCons) {
    var ref, ref1, ref2;
    return {
        "@type": JsonLDType.Review,
        itemReviewed: {
            "@type": JsonLDType.Vehicle,
            image: review === null || review === void 0 ? void 0 : (ref = review.gallery) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.image) === null || ref2 === void 0 ? void 0 : ref2.filename,
            name: "".concat(review === null || review === void 0 ? void 0 : review.make, " ").concat(review === null || review === void 0 ? void 0 : review.model)
        },
        reviewRating: {
            "@type": "Rating",
            ratingValue: "".concat(review === null || review === void 0 ? void 0 : review.starRating),
            bestRating: "10",
            worstRating: "1",
            author: {
                "@type": "Person",
                name: "".concat(review === null || review === void 0 ? void 0 : review.authorName) || "cinch"
            }
        },
        positiveNotes: {
            "@type": "ItemList",
            itemListElement: prosAndCons.pros.map(function(items, index) {
                return {
                    "@type": "ListItem",
                    position: index + 1,
                    name: items.text
                };
            })
        },
        negativeNotes: {
            "@type": "ItemList",
            itemListElement: prosAndCons.cons.map(function(items, index) {
                return {
                    "@type": "ListItem",
                    position: index + 1,
                    name: items.text
                };
            })
        },
        author: {
            "@type": "Person",
            name: (review === null || review === void 0 ? void 0 : review.authorName) || "cinch"
        },
        publisher: {
            "@type": "Organization",
            name: "cinch"
        },
        reviewBody: review === null || review === void 0 ? void 0 : review.snippet,
        datePublished: publishedDate.split("T")[0]
    };
};
export var formatTimeTo24Hour = function(storeTime) {
    if (storeTime === "Closed") return storeTime;
    var ref = _sliced_to_array(storeTime.split(" "), 2), time = ref[0], meridiem = ref[1];
    var ref1 = _sliced_to_array(time.split(":"), 2), hour = ref1[0], minute = ref1[1];
    var hour24 = meridiem === "AM" ? hour : Number.parseInt(hour) + 12;
    return "".concat(hour24, ":").concat(minute);
};
export var formatOpeningHours = function(openingHoursList) {
    return openingHoursList.map(function(openingHours) {
        var openingTime = openingHours.openingTime, closingTime = openingHours.closingTime, daysOfWeek = openingHours.daysOfWeek;
        var days = daysOfWeek.includes(" - ") ? daysOfWeek.split(" ").map(function(day) {
            return day.slice(0, 2);
        }).join("") : daysOfWeek.slice(0, 2);
        if (openingTime === "Closed" || closingTime === "Closed") return "".concat(days, " ").concat(openingTime);
        return "".concat(days, " ").concat(formatTimeTo24Hour(openingTime), "-").concat(formatTimeTo24Hour(closingTime));
    });
};
export var formatStoreToSchema = function(param) {
    var storeSlug = param.storeSlug, storeAddress = param.storeAddress, mapInformation = param.mapInformation, phoneNumbers = param.phoneNumbers, openingHoursList = param.openingHoursList, storeImageUrl = param.storeImageUrl;
    var ref = mapInformation[0], latitude = ref.latitude, longitude = ref.longitude;
    var phoneNumber = phoneNumbers[0].phoneNumber;
    return {
        "@type": JsonLDType.AutomotiveBusiness,
        name: "cinch store ".concat(storeSlug),
        image: storeImageUrl,
        "@id": "https://cinch.co.uk/store/".concat(storeSlug),
        url: "https://cinch.co.uk/store/".concat(storeSlug),
        telephone: phoneNumber,
        address: {
            "@type": "PostalAddress",
            streetAddress: "".concat(storeAddress.addressLine1, ", ").concat(storeAddress.addressLine2),
            addressLocality: storeAddress.townOrCity,
            postalCode: storeAddress.postCode,
            addressCountry: "GB"
        },
        geo: {
            "@type": "GeoCoordinates",
            latitude: latitude,
            longitude: longitude
        },
        openingHours: formatOpeningHours(openingHoursList),
        contactPoint: {
            "@type": "ContactPoint",
            telephone: phoneNumber,
            contactType: "customer service",
            areaServed: "GB",
            availableLanguage: "English"
        },
        sameAs: [
            "https://www.facebook.com/cinchuk",
            "https://twitter.com/cinchuk",
            "https://www.instagram.com/cinchuk", 
        ]
    };
};
